<div class="sidebar" *ngIf="sidebarExists">
  <p class="sidebar-title">{{sidebarTitle}}</p>
  <nav class="nav-sidebar flex-column">
    <div [ngSwitch]="navitem.itemtype" *ngFor="let navitem of sidebarNavitems">
      <p *ngSwitchCase="'headline'" class="sidebar-headline"><span class="sidebar-iconbox"><fa-icon [icon]="navitem.icon"></fa-icon></span> {{navitem.title}}</p>
      <a *ngSwitchDefault class="nav-link"
                          routerLink="{{navitem.link}}"
                          routerLinkActive="active"
                          [ngClass]="{'sidebar-sublink': navitem.itemtype == 'sublink'}">
        <span class="sidebar-iconbox"><fa-icon *ngIf="navitem.icon.length" [icon]="navitem.icon"></fa-icon></span> {{navitem.title}}
      </a>
    </div>
  </nav>
</div>
