import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {
  static errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('ERROR appeared!');
    return throwError(error);
  }

  constructor() { }
}
