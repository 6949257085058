import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiledownloaderComponent } from './filedownloader/filedownloader.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [FiledownloaderComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    FiledownloaderComponent
  ]
})
export class FiledownloaderModule { }
