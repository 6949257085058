import { Toastmessage } from './../interfaces/toastmessage';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastmessageStoreService {
  public toastmessages: Toastmessage[] = [];

  constructor() { }

  show(header: string, body: string) {
    this.toastmessages.push({ header, body });
  }

  remove(toast: Toastmessage) {
    this.toastmessages = this.toastmessages.filter(t => t !== toast);
  }

}
