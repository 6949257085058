import { Component, OnInit } from '@angular/core';
import { FileuploaderControllerService } from 'src/app/shared/services/fileuploader-controller.service';

@Component({
  selector: 'wol-fileuploader',
  templateUrl: './fileuploader.component.html',
  styleUrls: ['./fileuploader.component.scss']
})
export class FileuploaderComponent implements OnInit {

  constructor(public fuc: FileuploaderControllerService) { }

  ngOnInit() {
  }

}
