import { Account } from './../interfaces/account';
import { AccountService } from './../services/account.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  constructor(public accountService: AccountService) { }

  private AuthToken = '';
  private newRequest: HttpRequest<any>;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.accountService.account !== undefined) {
      this.AuthToken = this.accountService.account.authtoken;
      this.newRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.AuthToken}`
        }
      });
    } else {
      this.newRequest = request.clone();
    }

    // this.logger.debug('request:', this.newRequest);
    return next.handle(this.newRequest).pipe(
      /*tap(
        event => // this.logger.debug('response success:', event),
        error => // console.error('response error:', error)
      )*/
    );
  }

}
