import { FileRaw } from './file-raw';
import { File } from './file';

export class FileFactory {
  static fromRaw(f: FileRaw): File {
    return {
      ...f
    };
  }
}
