import { Active } from './active';
import { AccounttypeRaw } from './accounttype-raw';
import { Accounttype } from './accounttype';

export class AccounttypeFactory {
  static fromRaw(at: AccounttypeRaw): Accounttype {
    return {
      ...at,
      status: {status: 'active'}
    };
  }
}
