import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { TreeModule } from '@circlon/angular-tree-component';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';

// fontawesome could be using single icon import to reduce data to be loaded. For development including all icons is easier
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { ContentheaderComponent } from './components/contentheader/contentheader.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { ToastmessagesComponent } from './components/toastmessages/toastmessages.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FormMessagesComponent } from './components/form-messages/form-messages.component';
import { CommonmodalsComponent } from './components/commonmodals/commonmodals.component';
import { LogpanelComponent } from './components/logpanel/logpanel.component';
import { FoldertreeComponent } from './components/foldertree/foldertree.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { SortablejsModule} from 'nxt-sortablejs';
import { MomentModule } from 'ngx-moment';
import { Ng5SliderModule } from 'ng5-slider';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [ContentheaderComponent, ThumbnailComponent, ToastmessagesComponent, LoaderComponent, FormMessagesComponent, CommonmodalsComponent, LogpanelComponent, FoldertreeComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    FontAwesomeModule,
    NgxDatatableModule,
    TreeModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
      autoFocus: true
    }),
    SortablejsModule.forRoot({
      animation: 200,
    }),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    AngularResizeEventModule,
    Ng5SliderModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: !environment.consoleLogging,
      serverLogLevel: NgxLoggerLevel.OFF,
      enableSourceMaps: environment.consoleLogging
    })
  ],
  providers: [
    CookieService,
  ],
  exports: [
    NgbModule,
    FontAwesomeModule,
    ContentheaderComponent,
    ThumbnailComponent,
    NgxDatatableModule,
    ToastmessagesComponent,
    LoaderComponent,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    TreeModule,
    ContextMenuModule,
    SortablejsModule,
    FormMessagesComponent,
    CommonmodalsComponent,
    LogpanelComponent,
    MomentModule,
    FoldertreeComponent,
    AngularResizeEventModule,
    Ng5SliderModule
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icons to the library for convenient access in other components
    library.addIconPacks(fas, far, fal, fad);
  }
}
