import { FileStoreService } from 'src/app/shared/services/file-store.service';
import { NGXLogger } from 'ngx-logger';
import { Active } from './../interfaces/active';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Account } from './../interfaces/account';
import { Injectable } from '@angular/core';
import { AccountRaw } from '../interfaces/account-raw';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { AccountFactory } from '../interfaces/account-factory';
import { ErrorhandlerService } from './errorhandler.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public account: Account;

  constructor(
    private logger: NGXLogger,
    private http: HttpClient
  ) { }

  changePassword(currentPassword: string, newPassword: string, verifyPassword: string): Observable<boolean> {
    return this.http.post<any[]>(
      `${environment.apiurl}/users/password`,
      {currentPassword: `${currentPassword}`, newPassword: `${newPassword}`, verifyPassword: `${verifyPassword}`}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  // tslint:disable-next-line: variable-name
  update(accountdata: any): Observable<any> {
    this.logger.debug('update user accountdata');
    return this.http.put<AccountRaw>(
      `${environment.apiurl}/users`,
      accountdata
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );

  }

  getAccount(authtoken?: string): Observable<Account> {
    let httpOptions: {};

    if (authtoken) {
      httpOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${authtoken}`
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({})
      };
    }

    return this.http.get<AccountRaw>(
      `${environment.apiurl}/users/me`,
      httpOptions
      ).pipe(
        retry(3),
        map(a => {
          this.logger.debug(a);
          this.account = AccountFactory.fromRaw(a);
          this.logger.debug(this.account);
        }),
        catchError(ErrorhandlerService.errorHandler)
      );
  }
}
