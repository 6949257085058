import { Injectable } from '@angular/core';
import { SidebarNavitem } from './../interfaces/sidebar-navitem';
import { SidebarNavigation } from './../interfaces/sidebar-navigation';

@Injectable({
  providedIn: 'root'
})
export class SidebarNavigationService {

  sidebarNavigations: SidebarNavigation[];

  constructor() {
    this.sidebarNavigations = [
      { id: 'facilities',
        title: 'Facilities',
        navitems: [
          {title: 'Dashboard', icon: ['far', 'info-square'], itemtype: 'mainlink', link: '/facilities/dashboard'},
          {title: 'Uelzen_1', icon: [], itemtype: 'mainlink', link: '/facilities/1'},
          {title: 'Uelzen_2', icon: [], itemtype: 'mainlink', link: '/facilities/2'},
          {title: 'Celle', icon: [], itemtype: 'mainlink', link: '/facilities/3'}
        ]
      },
      { id: 'account',
        title: 'Account',
        navitems: [
          {title: 'Overview', icon: [], itemtype: 'mainlink', link: '/account/overview'},
          {title: 'Profile', icon: ['far', 'user'], itemtype: 'headline', link: ''},
          {title: 'Edit profile', icon: [], itemtype: 'mainlink', link: '/account/profile'},
          {title: 'Password', icon: [], itemtype: 'mainlink', link: '/account/password'},
          // {title: 'Notifications', icon: [], itemtype: 'mainlink', link: '/account/notifications'},
          // {title: 'Billing', icon: [], itemtype: 'mainlink', link: '/account/billing'},
          // {title: 'Group', icon: ['far', 'users'], itemtype: 'headline', link: ''},
          // {title: 'Members', icon: [], itemtype: 'mainlink', link: '/account/groupmembers'},
          // {title: 'Invite', icon: [], itemtype: 'mainlink', link: '/account/groupinvite'}
        ]
      },
      { id: 'support',
        title: 'Support',
        navitems: [
          {title: 'Tutorials', icon: ['far', 'magic'], itemtype: 'mainlink', link: '/support/tutorials'},
          {title: 'Frequent Questions', icon: ['far', 'question'], itemtype: 'mainlink', link: '/support/frequentquestions'},
          {title: 'About Us', icon: ['far', 'coffee'], itemtype: 'mainlink', link: '/support/aboutus'},
          {title: 'Contact', icon: ['far', 'envelope'], itemtype: 'mainlink', link: '/support/contact'},
          {title: 'Info & Legal Notices', icon: ['far', 'info-circle'], itemtype: 'mainlink', link: '/support/legalnotices'}
        ]
      },
      { id: 'admin',
        title: 'Administration',
        navitems: [
          {title: 'Overview', icon: [], itemtype: 'mainlink', link: '/admin/overview'},
          {title: 'Accounts', icon: ['far', 'users'], itemtype: 'headline', link: ''},
          {title: 'ActivityLog', icon: [], itemtype: 'mainlink', link: '/admin/activitylog'},
          {title: 'Users/Accounts', icon: [], itemtype: 'mainlink', link: '/admin/accounts'},
          {title: 'AccountTypes', icon: [], itemtype: 'mainlink', link: '/admin/accounttypes'},
          {title: 'Notifications', icon: [], itemtype: 'mainlink', link: '/admin/notifications'},
          {title: 'Articles', icon: [], itemtype: 'mainlink', link: '/admin/articles'},
          {title: 'Support', icon: ['far', 'life-ring'], itemtype: 'headline', link: ''},
          {title: 'Frequent Questions', icon: [], itemtype: 'mainlink', link: '/admin/frequentquestions'}
        ]
      }
    ];
  }

  getAll() {
    return this.sidebarNavigations;
  }

}
