import { FiledownloaderControllerService } from './../../shared/services/filedownloader-controller.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-filedownloader',
  templateUrl: './filedownloader.component.html',
  styleUrls: ['./filedownloader.component.scss']
})
export class FiledownloaderComponent implements OnInit {

  constructor(public fdc: FiledownloaderControllerService) { }

  ngOnInit() {
  }

}
