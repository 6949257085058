import { LoginlayoutComponent } from './loginlayout/loginlayout.component';
import { MainlayoutComponent } from './mainlayout/mainlayout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../shared/guards/auth.guard';

const routes: Routes = [
  { path: 'login',
    component: LoginlayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../login/login.module').then(mod => mod.LoginModule),
      }
    ]
  },
  { path: '',
    component: MainlayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'facilities',
        pathMatch: 'full',
        canActivateChild: [AuthGuard]},
      {
        path: 'facilities',
        loadChildren: () => import('../section-facilities/section-facilities.module').then(mod => mod.SectionDataModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'account',
        loadChildren: () => import('../section-account/section-account.module').then(mod => mod.SectionAccountModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'support',
        loadChildren: () => import('../section-support/section-support.module').then(mod => mod.SectionSupportModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'admin',
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('../section-administration/section-administration.module').then(mod => mod.SectionAdministrationModule),
        canActivateChild: [AuthGuard],
        data: {role: 'admin'}
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
