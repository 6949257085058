import { NGXLogger } from 'ngx-logger';
import { ToastmessageStoreService } from './toastmessage-store.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class FiledownloaderControllerService {

  public visibleSource = new BehaviorSubject<boolean>(false);
  public visible = this.visibleSource.asObservable();
  public downloadpreparationrunning = false;
  public checkdlstatus: any;
  public content = {title: 'Preparing Download', message: 'waiting for server...'};

  public visibleUpdate(value: boolean) {
    this.visibleSource.next(value);
  }

  public checkdownloadstatus(idcode) {
    // emit value in sequence every 1 second
    const checkinterval = interval(1000);

    this.checkdlstatus = checkinterval.subscribe(val => {
      this.http.post<any>(
        `${environment.apiurl}/filestorage/checkdownloadstatus`,
        {downloadid: idcode}
      ).subscribe(result => {
        this.logger.debug(result);
        if (result.prepareditems <  result.totalitems) {
          this.logger.debug(this.content.title);
          this.content.message = result.prepareditems + ' / ' + result.totalitems + ' items ready';
        } else {
          this.checkdlstatus.unsubscribe();
          this.content.message = 'Download will start automatically...';
          setTimeout(() => {
            this.visibleUpdate(false);
          }, 5000);
          this.downloadpreparationrunning = false;

          // tslint:disable-next-line: max-line-length
          window.location.assign(environment.apiurl + '/filestorage/deliverdownload/' + idcode + '?_t=' + this.account.account.authtoken);
        }
      });
    });
  }

  public requestnewdownload(folderpath, selectedobjects) {
    if (this.downloadpreparationrunning !== true) {
      this.downloadpreparationrunning = true;

      this.logger.debug('download');
      let itemstring = folderpath + '!';

      _.each(selectedobjects, (element, index, list) => {
        if (element.type !== '') {
          itemstring += element.type;
          itemstring += ':';
          itemstring += encodeURIComponent(element.path);
          itemstring += ':';
          itemstring += encodeURIComponent(element.name);
          itemstring += ';';
        }
      });

      this.logger.debug(itemstring);

      this.http.post<any>(
        `${environment.apiurl}/filestorage/download`,
        {downloadcode: encodeURIComponent(itemstring)}
      ).subscribe(result => {
        this.logger.debug(result);
        this.visibleUpdate(true);
        this.checkdownloadstatus(result.id);
      });
    } else {
      this.toastService.show('ERROR: Download', 'only one download a time is allowed');
    }
  }

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    public toastService: ToastmessageStoreService,
    public account: AccountService
  ) { }
}
