import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { ErrorhandlerService } from './errorhandler.service';
import { Notification } from '../interfaces/notification';
import { NotificationFactory } from '../interfaces/notification-factory';
import { NotificationRaw } from '../interfaces/notification-raw';

@Injectable({
  providedIn: 'root'
})
export class NotificationStoreService {

  public ownNotifications: Notification[];
  public allNotifications: Notification[];
  public ownLastrefresh = 0;
  public allLastrefresh = 0;
  public ownNewNotificationsCount = 0;

  constructor(private http: HttpClient, private logger: NGXLogger) { }

  getOwn(): Observable<Notification[]> {
    this.ownLastrefresh = Date.now();
    this.logger.debug('refreshing notifications own');
    return this.http.get<NotificationRaw[]>(
      `${environment.apiurl}/notifications/get-my-notifications`
      ).pipe(
        retry(3),
        map(notificationsRaw => notificationsRaw.map(n => NotificationFactory.fromRaw(n))
        ),
        map(notifications => this.ownNotifications = notifications),
        catchError(ErrorhandlerService.errorHandler)
      );
  }
  getOwnNewCount(): Observable<any> {
    this.ownLastrefresh = Date.now();
    this.logger.debug('refreshing notifications own');
    return this.http.get<any>(
      `${environment.apiurl}/notifications/list-own-new-count`
      ).pipe(
        retry(3),
        map(notifications => this.ownNewNotificationsCount = notifications),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  getAll(): Observable<Notification[]> {
    this.allLastrefresh = Date.now();
    this.logger.debug('refreshing notifications own');
    return this.http.get<NotificationRaw[]>(
      `${environment.apiurl}/notifications`
      ).pipe(
        retry(3),
        map(notificationsRaw => notificationsRaw.map(n => NotificationFactory.fromRaw(n))
        ),
        map(notifications => this.allNotifications = notifications),
        catchError(ErrorhandlerService.errorHandler)
      );
  }
}
