import { NotificationStoreService } from 'src/app/shared/services/notification-store.service';
import { AccountService } from './../../shared/services/account.service';
import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'wol-statusmenu',
  templateUrl: './statusmenu.component.html',
  styleUrls: ['./statusmenu.component.scss']
})
export class StatusmenuComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('statusmenu') statusmenu: ElementRef;
  public showmenu = false;

  constructor(
    private logger: NGXLogger,
    private as: AuthService,
    public accountService: AccountService,
    private renderer: Renderer2,
    public ns: NotificationStoreService
  ) {
    this.renderer.listen('window', 'click', (e: any) => {
      /**
       * Only run when toggleButton is no t clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      // this.logger.debug(e);
      if (this.showmenu) {
        // if ((e.target !== this.toggleButton.nativeElement) && (e.path.indexOf( this.statusmenu.nativeElement ) === -1)) {
        if ((e.path.indexOf( this.toggleButton.nativeElement ) === -1) && (e.path.indexOf( this.statusmenu.nativeElement ) === -1)) {
          this.showmenu = false;
        }
      }
    });
  }

  ngOnInit() {
  }

  logout() {
    this.logger.debug('logout User');
    this.as.logout();
  }

}
