import { ActivityRaw } from './activity-raw';
import { Activity } from './activity';

export class ActivityFactory {
  static fromRaw(a: ActivityRaw): Activity {
    return {
      ...a
    };
  }
}
