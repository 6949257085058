<div id="toastmessages">
  <ngb-toast
    *ngFor="let toast of toastService.toastmessages"
    [autohide]="true"
    [delay]="5000"
    (hide)="toastService.remove(toast)"
    header="{{toast.header}}"
  >
    {{toast.body}}
  </ngb-toast>
</div>

