<wol-header></wol-header>

<div class="container-fluid mainframe">
  <wol-toastmessages aria-live="polite" aria-atomic="true"></wol-toastmessages>
  <wol-fileuploader></wol-fileuploader>
  <wol-filedownloader></wol-filedownloader>
  <wol-sidebar></wol-sidebar>
  <div class="contentframe">
    <div class="contentframe_inner">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
