<nav class="navbar navbar-header bg-light mb-5">
  <a class="navbar-brand" routerLink=""><img src="assets/images/wolution_161.png"></a>

  <div class="navbar-expand mr-auto">
    <div class="navbar-nav-top">
      <a class="nav-item nav-link" routerLink="/facilities" routerLinkActive="active">Facilities</a>
      <a class="nav-item nav-link" routerLink="/account" routerLinkActive="active">Account</a>
      <a class="nav-item nav-link" routerLink="/support" routerLinkActive="active">Support</a>
      <a class="nav-item nav-link" *ngIf="accountService.account?.accounttype.name == 'Administrator'" routerLink="/admin" routerLinkActive="active">Administration</a>
    </div>
  </div>

  <div class="navbar-expand ml-auto navbar-nav">
    <div class="navbar-nav">
      <wol-statusmenu></wol-statusmenu>
    </div>
  </div>
</nav>
