import { AccounttypeStoreService } from './../services/accounttype-store.service';
import { Accounttype } from './accounttype';
import { AccountRaw } from './account-raw';
import { Account } from './account';
import { Active } from './active';
import * as _ from 'lodash';

export class AccountFactory {

  static active: Active;
  static accounttype: Accounttype;

  constructor(public ats: AccounttypeStoreService) { }


  static fromRaw(a: AccountRaw): Account {
    return {
      ...a,
      status: this.createActive(a.status),
      accountId: a.userId,
      accounttype: this.getAccounttype(a.accounttype)
    };
  }

  static createActive(value: string) {
    this.active = { status: 'active' };
    return this.active;
  }

  static getAccounttype(value: any) {
    if (typeof value === 'string') {
      if (value === '544d0e1ab104480c3547a11b') {
        return {
          _id: value,
          accountTypeId: 0,
          name: 'Administrator',
          group: 'yes',
          status: this.active,
          createdate: ''
        };
      } else {
        return {
          _id: value,
          accountTypeId: 1,
          name: 'Professional',
          group: 'yes',
          status: this.active,
          createdate: ''
        };
      }
    } else {
      if (value.name) {
        return value;
      } else {
        return {
          _id: value,
          accountTypeId: 1,
          name: 'Professional',
          group: 'yes',
          status: this.active,
          createdate: ''
        };
      }
    }
  }
}
